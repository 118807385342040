import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../context/AuthContext';
import Navbar from '../../components/marketplace/nav/nav';

import styles from '../../components/completion/PinsDisplay.module.css';
import { PinsDisplay, TopUpsDisplay, DonationsDisplay } from '../../components/completion/PinsDisplay';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  const handleError = (error, errorInfo) => {
    console.error('Error caught in ErrorBoundary: ', error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    return <div>Something went wrong.</div>;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      {React.Children.map(children, (child) => React.cloneElement(child, { onError: handleError }))}
    </React.Suspense>
  );
}

export default function VouchersByTransaction() {
  const { authState } = useAuth();
  const user = authState;

  let transactionId = null;

  if (typeof window !== 'undefined' && !transactionId) {
    const params = new URLSearchParams(window.location.search);
    transactionId = params.get('transactionId');
  }

  const {
    data: vouchers,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['vouchers', transactionId],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_VOUCHERS_URL}/e-vouchers?transactionId=${transactionId}`,
      );
      if (!response.ok) {
        throw new Error('Failed to fetch transactions.');
      }
      const data = await response.json();
      return data;
    },
  });

  if (isLoading) {
    return (
      <main className={styles.main}>
        <Navbar />
        <div className={styles.contentBody}>
          <div className={styles.navSidedBody}>
            <div className={styles.dealHolder}>
              <div className={styles.pageHeading}>Vouchers processed</div>
              <CircularProgress style={{ color: '#0fbc49' }} size={12} />
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (error) {
    return (
      <main className={styles.main}>
        <Navbar />
        <div className={styles.contentBody}>
          <div className={styles.navSidedBody}>
            <div className={styles.dealHolder}>
              <div className={styles.pageHeading}>Vouchers processed</div>
              Error: {error.message}
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (!user) {
    return (
      <main className={styles.main}>
        <Navbar />
        <div className={styles.contentBody}>
          <div className={styles.navSidedBody}>
            <div className={styles.dealHolder}>
              <div className={styles.pageHeading}>Vouchers processed</div>
              <div>You need to be logged in to view this page.</div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className={styles.main}>
      <Navbar />
      <div className={styles.contentBody}>
        <div className={styles.navSidedBody}>
          <div className={styles.dealHolder}>
            <h1 className={styles.h1}>Vouchers processed</h1>
            {vouchers?.length > 0 ? (
              vouchers.map((voucher, index) =>
                voucher?.error ? (
                  <div key={index}>
                    <strong>{voucher.voucherName}</strong>
                    <br />
                    <br />
                    An error occurred while processing this voucher. Contact support by clicking the chat icon on the
                    bottom right of the page. <br />
                    Reference: <strong>{voucher.transactionId}</strong>
                    <br />
                  </div>
                ) : (
                  <ErrorBoundary key={index}>
                    {voucher.redemptionType === 'PIN' ? (
                      <PinsDisplay transactionId={transactionId ?? ''} item={voucher.cartItem} user={user} />
                    ) : voucher.redemptionType === 'DONATION' ? (
                      <DonationsDisplay item={voucher.cartItem} user={user} />
                    ) : (
                      <TopUpsDisplay item={voucher.cartItem} user={user} />
                    )}
                  </ErrorBoundary>
                ),
              )
            ) : (
              <div>
                No vouchers found.
                <br />
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
