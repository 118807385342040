import React from 'react';
import styles from './DeliveryFee.module.css';

const DeliveryFee = ({ redemptionCurrency, setDeliveryFee, deliveryFee }) => {
  const currentFee = deliveryFee || 0;

  const deliveryOptions = [
    { id: 'collection', label: 'Collection', fee: 0 },
    { id: 'inner-city', label: 'Inner-city delivery', fee: 5 },
    { id: 'outskirts', label: 'Outskirts delivery', fee: 8 },
  ];

  const handleDeliveryChange = (fee) => {
    setDeliveryFee(fee);
  };

  return (
    <div className={styles.deliveryOptions}>
      <h3>Delivery options</h3>
      {deliveryOptions.map((option) => (
        <div key={option.id} className={styles.deliveryOption}>
          <input
            type="radio"
            id={option.id}
            name="deliveryOption"
            checked={currentFee === option.fee}
            onChange={() => handleDeliveryChange(option.fee)}
          />
          <label htmlFor={option.id}>
            <span className={styles.optionLabel}>{option.label}</span>
            <span className={styles.optionPrice}>
              {option.fee === 0 ? 'Free' : `${redemptionCurrency} ${option.fee.toFixed(2)}`}
            </span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default DeliveryFee;
