import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../hooks/useSubscription';
import { addNewsSubscriber } from '../utils/firebase';
import { Loader2 } from 'lucide-react';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const { subscribe } = useSubscription({ addNewsSubscriber });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await subscribe({ email });
      setSubmitStatus('success');
      setEmail('');

      setTimeout(() => {
        setSubmitStatus(null);
      }, 3000);
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubmitStatus('error');

      // Clear error message after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <footer className="bg-navy-900 text-white pt-16 foot">
      <section className="black">
        <div>
          <div className="footersec1">
            <h3 className="text-lg font-semibold mb-4 joinh3">Join our newsletter to keep up to date with us</h3>

            <form onSubmit={handleSubmit} className="mb-4">
              <div className="flex shift relative">
                <input
                  type="email"
                  placeholder="Email"
                  required
                  id="s-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-800 text-white px-4 py-2 rounded-l-full w-full"
                  disabled={isLoading}
                />
                <button
                  type="submit"
                  className="bg-white text-green-600 px-6 py-2 rounded-r-full flex items-center justify-center min-w-[120px]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      <span>Sending...</span>
                    </>
                  ) : (
                    'Subscribe'
                  )}
                </button>
              </div>

              {submitStatus && (
                <div
                  className={`mt-2 p-2 rounded-lg text-sm ${
                    submitStatus === 'success'
                      ? 'bg-green-50 text-green-700 border border-green-200'
                      : 'bg-red-50 text-red-700 border border-red-200'
                  }`}
                >
                  {submitStatus === 'success'
                    ? 'Successfully subscribed to our newsletter!'
                    : 'Failed to subscribe. Please try again.'}
                </div>
              )}
            </form>
          </div>
        </div>

        <div className="footersec2">
          <div>
            <Link to="/">
              <img src="/img/footer/logo-white2.png" alt="Lipaworld" className="h-8 mb-4" />
            </Link>
            <p className="text-sm text-gray-400 sec2p">Experience Simple and Secure Transactions with Lipaworld</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Platform</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-white">
                  Home
                </Link>
              </li>

              <li>
                <Link to="/marketplace" className="text-gray-400 hover:text-white">
                  Shop
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about-us" className="text-gray-400 hover:text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/partners" className="text-gray-400 hover:text-white">
                  Partners
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-gray-400 hover:text-white">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-white">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Socials</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://instagram.com/lipaworldafrica/"
                  className="text-gray-400 hover:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/LipaworldAfrica"
                  className="text-gray-400 hover:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href="https://web.facebook.com/profile.php?id=100093207047292"
                  className="text-gray-400 hover:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/company/lipaworld"
                  className="text-gray-400 hover:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@Lipaworldafrica"
                  className="text-gray-400 hover:text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8">
          <div className="flex flex-wrap justify-between items-center">
            <p className="text-sm text-gray-400">&copy; 2023-2025 Lipaworld Corp. All rights reserved</p>
            <div className="flex space-x-4 mt-4 lg:mt-0">
              <Link to="/terms-of-use" className="text-sm text-gray-400 hover:text-white">
                Terms of Service
              </Link>
              <Link to="/privacy-policy" className="text-sm text-gray-400 hover:text-white">
                Privacy Policy
              </Link>
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-4">
            Lipaworld Corp, a Delaware corporation, operates as an agent of the payee. Our services are subject to the
            terms and conditions outlined in our user agreements. Lipaworld is not a bank, and funds held in connection
            with our services are not insured by the FDIC. Please review our{' '}
            <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/terms-of-use">Terms of Service</Link> for
            more information.
          </p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
