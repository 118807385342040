import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './EditRecipientModal.module.css';
import { useAuth } from '../../../context';

const CowryNumberModal = ({ onClose, recipient }) => {
  const [loading, setLoading] = useState(false);
  const { authState } = useAuth();
  const [formData, setFormData] = useState({
    cowryPhoneNumber: recipient?.cowryPhoneNumber || '',
  });
  const [error, setError] = useState('');

  const handlePhoneNumberChange = (value) => {
    setError('');
    setFormData((prev) => ({
      ...prev,
      cowryPhoneNumber: `+${value}`,
    }));
  };

  const validatePhoneNumber = (number) => {
    // Update validation to work with international format
    return number.startsWith('+234') && number.length >= 13 && number.length <= 14;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate phone number
    if (!validatePhoneNumber(formData.cowryPhoneNumber)) {
      setError('Please enter a valid Nigerian phone number');
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('token');

    if (!token || !authState?.id) {
      toast.error('Authentication required. Please log in.');
      setLoading(false);
      return;
    }

    try {
      const { id, ...recipientWithoutId } = recipient;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipient?recipientId=${recipient.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          ...recipientWithoutId,
          cowryPhoneNumber: formData.cowryPhoneNumber,
          senderId: authState?.id,
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to update Cowry number');
      }

      toast.success('Cowry number updated successfully');
      onClose(response.data);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update Cowry number';
      console.error('Failed to update Cowry number:', err);
      toast.error(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Add {recipient.name}&apos;s Cowry phone number</h2>
          <button className={styles.closeButton} onClick={() => onClose(null)} type="button">
            ×
          </button>
        </div>

        <form className={styles.createForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Cowry phone number (Nigeria only)</label>
            <PhoneInput
              country="ng"
              value={formData.cowryPhoneNumber.replace('+', '')}
              onChange={handlePhoneNumberChange}
              required
              enableSearch={false}
              onlyCountries={['ng']}
              inputProps={{
                required: true,
                placeholder: 'Enter Cowry phone number',
              }}
              inputStyle={{
                width: '100%',
                height: '40px',
                fontSize: '16px',
                borderRadius: '4px',
              }}
              containerStyle={{
                width: '100%',
              }}
            />
            {error && <div className={styles.errorMessage}>{error}</div>}
          </div>

          <small>You are seeing this because you have cowry product(s) in your cart</small>

          <div className={styles.modalActions}>
            <button type="button" className={styles.cancelButton} onClick={() => onClose(null)}>
              Cancel
            </button>
            <button type="submit" className={styles.createButton} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CowryNumberModal;
