import React, { useEffect, useState, useTransition } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useTransaction, useCart, useCountries } from '../../../context';
import { useNavigate } from 'react-router-dom';

import styles from './PaypalBlock.module.css';
import mapOrderItems from './utils/mapOrderItems';
import axios from 'axios';

export default function PaypalBlock() {
  const navigate = useNavigate();
  const [isTransitionPending, startTransition] = useTransition();

  const transaction = useTransaction();
  const countries = useCountries();
  const cart = useCart();
  const [searchParams, setSearchParams] = useState(null);

  // console.log('cart', cart);
  // console.log('transaction', transaction);

  useEffect(() => {
    startTransition(() => {
      const params = new URLSearchParams(window.location.search);
      setSearchParams(params);
    });
  }, []);

  useEffect(() => {
    const hasCowryProduct = () => {
      if (cart && cart.cartItems) {
        return cart.cartItems.some((item) => item.deal?.merchantName === 'Cowry');
      }
      return false;
    };

    if (hasCowryProduct()) {
      const url = `${process.env.REACT_APP_API_VOUCHERS_URL}/health-cowry`;
      axios
        .get(url)
        .then(() => {
          console.log('success');
        })
        .catch((error) => {
          console.error('failed', error);
        });
    }
  }, [cart]);

  useEffect(() => {
    const hasCrossroadsProduct = () => {
      if (cart && cart.cartItems) {
        return cart.cartItems.some((item) => item.deal?.partnerName === 'Crossroads');
      }
      return false;
    };

    if (hasCrossroadsProduct()) {
      const url = `${process.env.REACT_APP_API_VOUCHERS_URL}/health-crossroads`;
      axios
        .get(url)
        .then(() => {
          console.log('success');
        })
        .catch((error) => {
          console.error('failed', error);
        });
    }
  }, [cart]);

  useEffect(() => {
    const hasPrepayProduct = () => {
      if (cart && cart.cartItems) {
        return cart.cartItems.some((item) => item.deal?.partnerName === 'Prepay Nation');
      }
      return false;
    };

    if (hasPrepayProduct()) {
      const url = `${process.env.REACT_APP_API_VOUCHERS_URL}/prepaynation/health`;
      axios
        .get(url)
        .then(() => {
          console.log('success');
        })
        .catch((error) => {
          console.error('failed', error);
        });
    }
  }, [cart]);

  const rate = countries.ZA?.rate;
  const orderItems = rate && cart ? mapOrderItems(cart.cartItems, rate, transaction) : null;
  const totalAmount = orderItems?.reduce((total, item) => total + item?.unit_amount?.value * item.quantity, 0);
  const transactionId = transaction?.transactionId;

  const onCreateOrder = async (data, actions) => {
    // console.log('orderItems', orderItems);
    // console.log('totalAmount', totalAmount);
    // console.log('transactionId', transactionId);
    if (!orderItems || !totalAmount || !transactionId) {
      console.error('Missing required data:', { orderItems, totalAmount, transactionId });
      return;
    }

    if (totalAmount <= 0) {
      console.error('Invalid total amount:', totalAmount);
      return;
    }

    try {
      return actions.order.create({
        purchase_units: [
          {
            description: 'Lipaworld',
            reference_id: transactionId,
            soft_descriptor: 'LIPAWORLD',
            items: orderItems,
            amount: {
              currency_code: 'USD',
              value: Number(totalAmount.toFixed(2)),
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: Number(totalAmount.toFixed(2)),
                },
              },
            },
          },
        ],
      });
    } catch (error) {
      console.error('PayPal order creation failed:', error);
      throw error;
    }
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      sessionStorage.setItem('payment', JSON.stringify(details));
      navigate(`/completion?id=${details.id}`);
    });
  };

  return (
    <main className={`${styles.main}`}>
      <div className={styles.contentBody}>
        {isTransitionPending ? (
          <p>LOADING...</p>
        ) : (
          <div className={styles.paymentsWrapper}>
            <div></div>
            <PayPalButtons
              createOrder={(data, actions) => onCreateOrder(data, actions)}
              onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
          </div>
        )}
      </div>
    </main>
  );
}
