export const calculateRedemptionValue = (item) => {
  if (item.deal?.customAmount === true) {
    return item?.redemptionInputValue ?? 0;
  } else {
    if (item.deal.usdAmount) {
      return item?.redemptionInputValue;
    } else {
      return (item?.redemptionInputValue ?? 0) / 100;
    }
  }
};
