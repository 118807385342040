import React from 'react';
import styles from './WalletFeatures.module.css';

const WalletFeatures = () => {
  return (
    <div className={styles.walletFeaturesContainer}>
      <h2 className={styles.walletFeaturesTitle}>
        Why Choose the Lipaworld Wallet?
      </h2>
      
      <div className={styles.featuresGrid} data-aos="fade-down">
        <div className={styles.featureCard}>
          <div className={styles.featureIconWrapper}>
            <div className={styles.walletIcon}><img src='/img/stablecoin/security-card.svg'/></div>
          </div>
          <h3 className={styles.featureTitle}>
            Stability and Security with USDC
          </h3>
          <p className={styles.featureDescription}>
            USDC, a 1:1 USD-backed stablecoin, offers stability, transparency, and peace of mind under U.S. regulations.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureIconWrapper}>
            <div className={styles.transferIcon}><img src='/img/stablecoin/flash.svg'/></div>
          </div>
          <h3 className={styles.featureTitle}>
            Fast, Low-Cost Transfers
          </h3>
          <p className={styles.featureDescription}>
            Send money quickly with low fees using our blockchain-powered solution.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureIconWrapper}>
            <div className={styles.rampIcon}><img src='/img/stablecoin/send-sqaure-2.svg'/></div>
          </div>
          <h3 className={styles.featureTitle}>
            Convenient Off-Ramp Options
          </h3>
          <p className={styles.featureDescription}>
            Cash out anytime through local partners or Lipaworld vendors, and share cash-out links for non-Lipa users to access funds.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureIconWrapper}>
            <div className={styles.goodsIcon}><img src='/img/stablecoin/money-forbidden.svg'/></div>
          </div>
          <h3 className={styles.featureTitle}>
            Pay for Essential Goods and Services
          </h3>
          <p className={styles.featureDescription}>
            Shop seamlessly on our marketplace, using your wallet to pay directly for essentials like airtime and groceries without intermediaries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletFeatures;