import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();
const CartDispatchContext = createContext();

const initialState = {
  cartItems: [],
  cartId: null,
  totalAmount: '0.00',
  transactionFee: '0.00',
  deliveryFee: '0.00',
  subtotal: '0.00',
  processingFee: '0.00',
  discounts: 0,
  currencyRate: {
    rate: 1,
    sourceCurrency: 'ZAR',
    destinationCurrency: 'USD',
    rateId: null,
  },
  deliveryFees: {},
};

function cartReducer(state, action) {
  switch (action.type) {
    case 'SET_CART_ITEMS':
      return {
        ...state,
        cartItems: action.payload,
      };

    case 'ADD_CART_ITEM':
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case 'REMOVE_CART_ITEM':
      const remainingItems = state.cartItems.filter((item) => item.cartItemId !== action.payload);
      return {
        ...state,
        cartItems: remainingItems,
      };

    case 'SET_CART_ID':
      return {
        ...state,
        cartId: action.payload,
      };

    case 'UPDATE_CART_AMOUNTS':
      return {
        ...state,
        totalAmount: action.payload.totalAmount,
        subtotal: action.payload.subtotal,
        transactionFee: action.payload.transactionFee,
        processingFee: action.payload.processingFee,
        deliveryFee: action.payload.deliveryFee,
        discounts: action.payload.discounts,
      };

    case 'UPDATE_CURRENCY_RATE':
      return {
        ...state,
        currencyRate: {
          rate: action.payload.rate,
          sourceCurrency: action.payload.sourceCurrency,
          destinationCurrency: action.payload.destinationCurrency,
          rateId: action.payload.rateId,
        },
      };

    case 'UPDATE_CART_RECIPIENTS':
      return {
        ...state,
        cartItems: state.cartItems.map((item) => ({
          ...item,
          recipientId: action.payload.recipientId,
          productRecipient: action.payload.productRecipient,
        })),
      };

    case 'CLEAR_CART':
      return initialState;

    default:
      return state;
  }
}

export function CartProvider({ children, initialState: providedInitialState }) {
  const [state, dispatch] = useReducer(cartReducer, providedInitialState || initialState);

  const value = { ...state, dispatch };

  return (
    <CartContext.Provider value={value}>
      <CartDispatchContext.Provider value={dispatch}>{children}</CartDispatchContext.Provider>
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return {
    ...context,
    updateDeliveryFee: (payload) => {
      context.dispatch({ type: 'UPDATE_DELIVERY_FEE', payload });
    },
  };
}

export function useDispatchCart() {
  return useContext(CartDispatchContext);
}
