import React from 'react';
import styles from './MoneyTransfer.module.css';

const MoneyTransfer = () => {
  return (
    <div className={styles.moneyTransferContainer}>
      <div className={styles.transparencyBadge} data-aos="fade-left">
        <span>No Hidden Fees</span>
        <span className={styles.dot}>•</span>
        <span>100% Transparency</span>
      </div>
      
      <h1 className={styles.moneyTransferTitle} data-aos="fade-right">
        Your Gateway to Seamless Money Transfers
      </h1>
      
      <p className={styles.moneyTransferDescription} data-aos="fade-right">
        Sending money to Africa just got faster, safer, and more affordable! Lipaworld's new Stablecoin Wallet lets you use USDC (Circle Dollar) to send money directly to loved ones, pay for essential goods and services, or cash out when you need it most. Designed with the African diaspora in mind, our wallet combines the power of blockchain technology with practical solutions for real-world needs.
      </p>

        <div className={styles.phoneWrapper} data-aos="fade-up">
          <img 
            src="/img/stablecoin/iPhone-16-Pro.svg" 
            alt="Money Transfer App Interface" 
            className={styles.phoneImage}
          />
        </div>
    </div>
  );
};

export default MoneyTransfer;