import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const CrossRoads = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/cr.png"
                alt="crossroads and lipaworld"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Press Release</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>13min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>January 9, 2025</span>
              </div>

              <h1 className="title2">Introducing Crossroads: Our latest Marketplace Partner from Zimbabwe!</h1>

              <p className="description2">
              <br />

              At Lipaworld, we believe in building connections that transcend borders and foster opportunities for communities worldwide. Today, we’re thrilled to announce the launch of a new grocery partner in the Zimbabwe marketplace: <strong>Crossroads</strong>, a trusted name in groceries and daily essentials based in Zimbabwe!

        <br />
        <br />
        <strong><h3>Why This Partnership Matters</h3></strong>
        <br />
        This collaboration marks a significant milestone in our journey to empower immigrants and their families. Crossroads has built a stellar reputation for offering top-quality products that cater to everyday needs and delivery services. By bringing them onto the Lipaworld marketplace, we’re creating a seamless platform where Zimbabweans at home and abroad can shop, connect, and share moments of joy. Purchased hampers will be eligible for delivery within the delivery radius.

        <br />
        <br />
        <strong><h3>What Crossroads Brings to the Table
        </h3></strong>
        <br />
        Crossroads specializes in a wide range of groceries, from pantry staples to fresh produce, carefully curated to meet the needs of Zimbabwean households. Whether you’re looking to send essentials to loved ones or make life easier for your family back home, Crossroads is here to deliver.
        <br />
        <br />
        <strong><h3>Connecting Communities Worldwide
        </h3></strong>
        <br />
        For those living far from home, the Lipaworld marketplace offers a bridge to connect you with trusted local businesses like Crossroads. Our mission is simple: to ensure that no matter where you are, you can still take care of the people who matter most.
        <br />
        <br />
        <strong><h3>Shop Now with Crossroads on Lipaworld!
        </h3></strong>
        <br />
        With this partnership, we’re making it easier than ever to share love and care across distances. Explore Crossroads’ products today and experience the convenience of shopping directly from Zimbabwe’s leading grocery provider.
        <br />
        <br />
        Visit <a href="https://www.instagram.com/crossroadsmart/" className="underline">CrossroadsMart</a> on Instagram to learn more about their incredible offerings, and head to the Lipaworld marketplace to start shopping!
        <br />
        <br />
        <strong><h3>Stay Connected
        </h3></strong>
        <br />
        This is just the beginning! Follow us on <a href="https://www.instagram.com/lipaworldafrica/?fbclid=IwZXh0bgNhZW0CMTEAAR1ChbcQFvPegUJZlVQNjNnJZZRugP0DR00uPEm9eMKx9s_tbpjWmHVnqvQ_aem_SlbNB_yAHtlsa6L7I3_aCg" className="underline">Instagram</a>, <a href="https://x.com/LipaworldAfrica" className="underline">Twitter</a>, and <a href="https://web.facebook.com/profile.php?id=100093207047292" className="underline">Facebook</a> for updates, offers, and stories of how Lipaworld is transforming lives.
        <br />
         <br />
        Together, we’re building a world where love and support know no boundaries. Welcome aboard, Crossroads!
        <br />
        <br />

              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default CrossRoads;
