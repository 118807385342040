import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import ProgressBar from '../../components/checkout/ProgressBar';
import Navbar from '../../components/marketplace/nav/nav';
import CartItems from '../../components/checkout/cartitem/CartItems';
import { useTransaction, useDispatchTransaction } from '../../context';

const CartPage = () => {
  const { authState } = useAuth();
  const transaction = useTransaction();
  const dispatchTransaction = useDispatchTransaction();

  const {
    data: exchangeRate,
    isLoading: isExchangeRateLoading,
    isError: isExchangeRateError,
    error: exchangeRateError,
  } = useQuery({
    queryKey: ['exchangeRate'],
    queryFn: async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/rate/latest`,
          timeout: 30000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });
        return response.data.rate;
      } catch (error) {
        let errorMessage = 'Failed to fetch exchange rate.';
        if (axios.isCancel(error)) {
          errorMessage = 'Request was cancelled';
        } else if (error.code === 'ECONNABORTED') {
          errorMessage = 'Request timed out';
        } else if (error.response) {
          errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
        }
        throw new Error(errorMessage);
      }
    },
  });

  const {
    data: verificationStatus,
    isLoading: isVerificationLoading,
    isError: isVerificationError,
    error: verificationError,
  } = useQuery({
    queryKey: ['very', authState?.id],
    queryFn: async () => {
      if (!authState?.id) {
        return 'User not found.';
      }
      const res = await fetch(`${process.env.REACT_APP_API_USERS_V1_URL}/very?userId=${authState?.id}`);
      const data = await res.json();
      return data.message;
    },
    enabled: !!authState?.id,
  });

  const {
    data: cartId,
    isLoading: isCartIdLoading,
    isError: isCartIdError,
    error: cartIdError,
  } = useQuery({
    queryKey: ['cartId'],
    queryFn: async () => {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/cart/cartid`,
        timeout: 30000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      dispatchTransaction({
        type: 'SET_CART_ID',
        payload: response.data.cartId,
      });
      return response.data.cartId;
    },
  });

  const {
    data: transactionId,
    isLoading: isTransactionIdLoading,
    isError: isTransactionIdError,
    error: transactionIdError,
  } = useQuery({
    queryKey: ['transactionId'],
    queryFn: async () => {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_CONFIGURATIONS_URL}/v1/transactions/transactionid`,
        timeout: 30000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('transactionId', response.data.transactionId);
      }
      dispatchTransaction({
        type: 'SET_TRANSACTION_ID',
        payload: response.data.transactionId,
      });
      return response.data.transactionId;
    },
  });

  useEffect(() => {
    if (authState?.id) {
      dispatchTransaction({
        type: 'UPDATE_PARTIES',
        payload: {
          senderId: authState.id,
          recipientIds: transaction?.recipientIds || [],
        },
      });
    }
  }, [authState?.id, dispatchTransaction]);

  useEffect(() => {
    if (transactionId) {
      dispatchTransaction({
        type: 'SET_TRANSACTION_ID',
        payload: transactionId,
      });
    }
  }, [transactionId, dispatchTransaction]);

  useEffect(() => {
    if (transaction) {
      sessionStorage.setItem('transaction', JSON.stringify(transaction));
    }
  }, [transaction]);

  const isLoading = isExchangeRateLoading || isVerificationLoading || isCartIdLoading || isTransactionIdLoading;
  const isError = isExchangeRateError || isVerificationError || isCartIdError || isTransactionIdError;
  const error = exchangeRateError || verificationError || cartIdError || transactionIdError;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  return (
    <>
      <Navbar />
      <ProgressBar />
      <CartItems
        exchangeRate={exchangeRate}
        verificationStatus={verificationStatus}
        cartId={cartId}
        transactionId={transactionId}
      />
    </>
  );
};

export default CartPage;
