import React from 'react';

const Impact = () => {

  const handleClick = () => {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfV3yVqbi-mTCriuNTjCQRGh4Qt_hYgDdj5kAXGxbABN0s-xQ/viewform';
  };

  return (
    <section className="getsec text-white py-12">
      <div className="container mx-auto px-4 banner-text-left">
        <div className="flex flex-col md:flex-row items-center" data-aos="fade-in">
          <div className="md:w-1/2 mb-4 md:mb-0" data-aos="fade-right">
            <h2 className="text-3xl font-bold mb-4 getheader">Ready to Make an Impact?</h2>
            <p className="mb-4 getp">Sending money home shouldn’t be hard. With the Lipaworld Stablecoin Wallet, you’re not just transferring funds—you’re empowering families, businesses, and communities across Africa. 
            </p>
            <button
              className=" text-green-600 px-6 py-2 rounded transition duration-300 getbutton"
              onClick={handleClick}
            >
              Join the waitlist
            </button>
          </div>
          <div className="md:w-1/2 md:pl-8" data-aos="fade-left">
            <img src="/img/stablecoin/iPhone.png" alt="Man using phone" className="getimg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Impact;
