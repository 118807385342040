import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './EditRecipientModal.module.css';
import { useAuth } from '../../../context';

const DeliveryAddressModal = ({ onClose, recipient }) => {
  const [loading, setLoading] = useState(false);
  const { authState } = useAuth();
  const [formData, setFormData] = useState({
    streetAddress: recipient?.streetAddress || '',
    city: recipient?.city || '',
    suburb: recipient?.suburb || '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (recipient?.country !== 'ZW') {
      setError('Deliveries can only be placed for recipients in Zimbabwe');
      return;
    }
  }, [recipient]);

  const handleChange = (field) => (e) => {
    setError('');
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const validateAddress = () => {
    if (recipient?.country !== 'ZW') {
      setError('Deliveries can only be placed for recipients in Zimbabwe');
      return false;
    }

    if (!formData.streetAddress.trim()) {
      setError('Street address is required');
      return false;
    }
    if (!formData.city.trim()) {
      setError('City is required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateAddress()) {
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('token');

    if (!token || !authState?.id) {
      toast.error('Authentication required. Please log in.');
      setLoading(false);
      return;
    }

    try {
      const { id, ...recipientWithoutId } = recipient;
      const response = await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipient?recipientId=${recipient.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          ...recipientWithoutId,
          streetAddress: formData.streetAddress,
          city: formData.city,
          suburb: formData.suburb,
          senderId: authState?.id,
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to update delivery address');
      }

      toast.success('Delivery address updated successfully');
      onClose(response.data);
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Failed to update delivery address';
      console.error('Failed to update delivery address:', err);
      toast.error(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Add {recipient.name}&apos;s delivery address</h2>
          <button className={styles.closeButton} onClick={() => onClose(null)} type="button">
            ×
          </button>
        </div>

        <form className={styles.createForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Country</label>
            <input
              type="text"
              value={recipient.country || '-'}
              readOnly
              className={`${styles.formGroup} ${styles.readOnlyInput}`}
            />
          </div>

          {error ? (
            <div className={styles.errorMessage}>{error}</div>
          ) : (
            <>
              <div className={styles.formGroup}>
                <label>Street address*</label>
                <input
                  type="text"
                  value={formData.streetAddress}
                  onChange={handleChange('streetAddress')}
                  placeholder="Enter street address"
                  required
                  disabled={recipient?.country !== 'ZW'}
                />
              </div>

              <div className={styles.formGroup}>
                <label>Suburb*</label>
                <input
                  type="text"
                  value={formData.suburb}
                  onChange={handleChange('suburb')}
                  placeholder="Enter suburb (optional)"
                  disabled={recipient?.country !== 'ZW'}
                />
              </div>

              <div className={styles.formGroup}>
                <label>City*</label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={handleChange('city')}
                  placeholder="Enter city"
                  required
                  disabled={recipient?.country !== 'ZW'}
                />
              </div>
            </>
          )}

          <small>You are seeing this because you have item(s) that require delivery in your cart.</small>

          <div className={styles.modalActions}>
            {recipient?.country === 'ZW' ? (
              <>
                <button type="button" className={styles.cancelButton} onClick={() => onClose(null)}>
                  Cancel
                </button>
                <button type="submit" className={styles.createButton} disabled={loading}>
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </>
            ) : (
              <button type="button" className={styles.returnButton} onClick={() => onClose(null)}>
                Return to Cart
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeliveryAddressModal;
