import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ title, author, date, readTime, description, slug, image }) => {
  return (
    <Link to={`/blog/${slug}`} className="blog-card cursor-pointer">
      <div className="cursor-pointer">
        <img src={image} alt={title} className="blog-image" />
        <div className="blog-content">
          <div className="metadata">
            {/* <span>Category</span> */}
            {/* <img src='/img/testimonials/rev1.png' alt="Dot" className="dot" /> */}
            <span>{date}</span>
            <img src='/img/testimonials/rev1.png' alt="Dot" className="dot" />
            <span>{readTime}</span>
          </div>
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
          <div className="author-section">
            {/* <img src='/img/testimonials/rev1.png' alt="Author" className="avatar" /> */}
            <span className="author-name">{author}</span>
            <button className="read-more-button">Read more</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

const AllBlogs = () => {
  const blogPosts = [
    {
      title: "Your Guide to Unlocking US Banking: A Step-by-Step Approach",
      author: "Lipaworld",
      date: "January 20, 2025",
      readTime: "17min read",
      description: "Landing in the United States is an exciting adventure, but...",
      slug: "unlocking-us-banking",
      image: '/img/blog/mancard.png'
    },
    {
      title: "The Truth About Remittances Nobody Tells You",
      author: "Lipaworld",
      date: "January 13, 2025",
      readTime: "13min read",
      description: "Remittances often appear as straightforward financial transactions. That is...",
      slug: "truth-about-remittances",
      image: '/img/blog/truth.png'
    },
    {
      title: "Introducing Crossroads: Our latest Marketplace Partner from Zimbabwe!",
      author: "Lipaworld",
      date: "January 9, 2025",
      readTime: "13min read",
      description: "At Lipaworld, we believe in building connections that transcend borders and foster opportunities for communities worldwide. Today, we’re...",
      slug: "crossroads-mart",
      image: '/img/blog/cr.png'
    },
    {
      title: "Productised Remittances: A New Era of Transparency, Purpose, and Growth With Lipaworld",
      author: "Lipaworld",
      date: "December 31, 2024",
      readTime: "18min read",
      description: " For decades, remittances have served as a vital economic lifeline for...",
      slug: "productised-remittances",
      image: '/img/blog/map.png'
    },
    {
      title: "You Made This Year Truly Special",
      author: "Lipaworld",
      date: "December 23, 2024",
      readTime: "13min read",
      description: " As the year draws to a close and twinkling Christmas lights fill every street, we can’t...",
      slug: "you-are-special",
      image: '/img/blog/people.jpg'
    },
    {
      title: "Sending a Gift Home This Christmas? Thoughtful Options Make All the Difference",
      author: "Lipaworld",
      date: "December 16, 2024",
      readTime: "10min read",
      description: "The holiday season is a time for giving, and for many immigrants, it’s also a time to...",
      slug: "sending-a-gift-home",
      image: '/img/blog/ImgFx.jpg'
    },
    {
      title: "Sharing Love Across Borders",
      author: "Lipaworld",
      date: "December 11, 2024",
      readTime: "10min read",
      description: "Here’s a guide to help you navigate the process of sending love home this holiday season.",
      slug: "sharing-love-across-borders",
      image: '/img/blog/Banner.png'
    },
    {
      title: "Lipaworld partners with CheckUps to unlock healthcare benefits for families of Kenyans back at home",
      author: "Lipaworld",
      date: "September 30, 2024",
      readTime: "10min read",
      description: "The innovative partnership offers secure remittances and inclusive healthcare benefits to families in Kenya, bridging the gap between...",
      slug: "Lipaworld-partners-with-CheckUps",
      image: '/img/blog/checkups.png'
    },
    {
      title: "Lipaworld Accepted into Prestigious VentureCrushFG Pod Program",
      author: "Lipaworld",
      date: "July 25, 2024",
      readTime: "8min read",
      description: "Lipaworld, a leading innovator in digital financial solutions, is thrilled to announce its acceptance into the VentureCrushFG Pod program. This significant milestone marks a pivotal step in...",
      slug: "Lipaworld-Accepted-into-Prestigious-VentureCrushFG-Pod-Program",
      image: '/img/blog/ventureCrush.png'
    },
    {
      title: "5 Thoughtful Gift Ideas for Father's Day",
      author: "Lipaworld",
      date: "June 16, 2024",
      readTime: "12min read",
      description: "Father's Day is here, and finding the perfect gift for the man who has given you so much can be a challenge—especially...",
      slug: "5-Thoughtful-Gift-Ideas-for-Fathers-Day",
      image: '/img/blog/fathersday.jpg'
    },
    {
      title: " Lipaworld Secures First Place in Fintech Category at Startup 302 Pitch Competition",
      author: "Lipaworld",
      date: "May 20, 2024",
      readTime: "15min read",
      description: "Lipaworld, an innovative digital voucher marketplace, clinches first place in the Fintech Category at the prestigious...",
      slug: "lipaworld-secures-first-place-in-fintech-category-at-startup-302-pitch-competition",
      image: '/img/blog/ceo.jpg'
    },
    // {
    //   title: "Sustainable Banking Practices",
    //   author: "Emma Thompson",
    //   date: "September 25, 2024",
    //   readTime: "7min read",
    //   description: "Discover how banks are incorporating environmental sustainability into their operations and services.",
    //   slug: "sustainable-banking-practices",
    //   image: '/img/blog/sustainable-banking.png'
    // }
  ];

  return (
    <div className="blogs-section">
      <h1 className='blogh1'>All Blogs</h1>
      <div className="blogs-grid">
        {blogPosts.map((post, index) => (
          <BlogCard
            key={index}
            {...post}
          />
        ))}
      </div>
    </div>
  );
};

export default AllBlogs;