import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Related from "../../components/blog/Related";
import Footer from "../../components/Footer";

const Special = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/blog");
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <div className="foster-container">
            <div className="button-main2" onClick={handleBack}>
              <img
                src="/img/blog/pages/arrow-left.png"
                alt="Back"
                className="arrow-left"
              />
              <span className="back-text">Back</span>
            </div>

            <div className="image-section2">
              <img
                src="/img/blog/people.jpg"
                alt="Sharing love across borders"
                className="community-image"
              />
            </div>

            <div className="content-section2">
              <div className="metadata2">
                <span>Article</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>13min read</span>
                <img
                  src="/img/blog/pages/Ellipse.png"
                  alt="Dot"
                  className="dot"
                />
                <span>December 23, 2024</span>
              </div>

              <h1 className="title2">You Made This Year Truly Special</h1>

              <p className="description2">
              <br />

              As the year draws to a close and twinkling Christmas lights fill every street, we can’t help but look back at the connections we’ve been privileged to witness. From heartfelt gifts sent home to timely remittances that brought smiles to loved ones, you made it all possible.

        <br />
        <br />
        <p>At Lipaworld, we’ve seen your stories unfold: families coming together despite the distance, parents supporting their children’s dreams, and friends exchanging joy across borders. For all this, we want to say one thing: <strong>Thank You.</strong>
        </p>
        <br />
        <br />
        <strong><h3>The Real Heroes: You, Our Users</h3></strong>
        <br />
        Throughout 2024, you’ve shown us that love isn’t bound by geography.
        <br />
        <br />
        <ul>
          <li>
          Every time you sent a gift or money home, you weren’t just completing a transaction, you were building connections that matter.
          </li>
          <br />
          <li>
          You reminded us that small gestures, like helping mom prepare Christmas dinner or surprising a loved one with a holiday gift, mean everything.
          </li>
        </ul>
        <br />
        Your trust in us has been the greatest gift of all.
        <br />
        <br />
        <strong><h3>The Year in Numbers: A Quick Celebration of Impact
        </h3></strong>
        <br />
        This year, together we’ve:
        <br />
        <br />
        <ul>
          <li>
          Most purchased category - Groceries
          </li>
          <li>
          Most purchased product - Flash-powered spaza shop voucher
          </li>
          <li>
          User locations - 2% South Africa, 2% United Kingdom, 96% United States
          </li>
          <li>
          Most view product - Shoprite Grocery ZAR250
          </li>
          <li>
          Most viewed category - Airtime
          </li>

        </ul>
        <br />
        <br />
        <strong><h3>A Shared Promise for 2025
        </h3></strong>
        <br />
        As we step into a new year, our promise remains the same:
        <br />
        <br />
        <ul>
          <li>
          To keep helping you stay connected to the people you love, no matter where life takes you.
          </li>
          <li>
          To make sending remittances and gifts home easier, faster, and even more reliable.
          </li>
        </ul>
        <br />
        <br />
        Because at the end of the day, it’s not about money or gifts. it’s about the relationships that make us who we are.
              </p>

              <div className="author-section2">
                {/* <img
                  src="/img/blog/rev1.png"
                  alt="Author"
                  className="avatar"
                /> */}
                <span className="author-name2">Lipaworld</span>
              </div>
            </div>
          </div>
          <Related />
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Special;
