const getRedemptionAmount = (item) => {
  if (!item?.deal) {
    return 0;
  }

  if (item.deal.customAmount && item.redemptionInputValue) {
    return Number(item.redemptionInputValue) * 100;
  }

  return item.deal.redemptionValues?.[0] || 0;
};

export default getRedemptionAmount;
