import React, { useState } from 'react';
import styles from '../styles/signup.module.css';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_USERS_URL}/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.message || 'Password reset request failed');
      }

      toast.success('Password reset code sent to your email');
      navigate('/reset', { state: { email } });
    } catch (error) {
      const errorMessage =
        error.name === 'TypeError'
          ? 'Unable to connect to the server. Please check your internet connection.'
          : error.message;

      toast.error(errorMessage);
      console.error('Password reset error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 5000,
          style: {
            background: '#333',
            color: '#fff',
          },
        }}
      />

      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src="/img/auth/logo-black2.png" alt="Lipaworld logo" className={styles.logo} />
          </Link>
        </div>

        <div className={styles.welcomeText}>
          <h1>Forgot your password?</h1>
          <p>Enter your email address below to receive a password reset code</p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              required
            />
          </div>

          <button type="submit" className={styles.signupButton} disabled={loading}>
            {loading ? 'Sending...' : 'Reset Password'}
          </button>

          <p className={styles.signin}>
            Remember your password? <Link to="/signin">Sign in here</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
