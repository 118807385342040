import React, { useState } from 'react';
import styles from './HowItWorks.module.css';

function HowItWorks() {
    const [activeSection, setActiveSection] = useState('topup');

    const renderRightContent = () => {
        switch(activeSection) {
            case 'topup':
                return (
                    <div className={styles.rightContent}>
                        <div className="text-contenthow">
                        <div className={styles.iconContainer}>
                            <img src='/img/stablecoin/import.svg' className='globe-iconhow'/>
                        </div>
                        <h3>Top-Up Your Wallet</h3>
                        <p>Add funds using USDC.</p>
                        </div>
                        <img src='/img/stablecoin/i1.png' className='mobile-imagehow'/>

                    </div>
                );
            case 'send':
                return (
                    <div className={styles.rightContent}>
                         <div className="text-contenthow">
                         <div className={styles.iconContainer}>
                         <img src='/img/stablecoin/discover.svg' className='globe-iconhow'/>
                        </div>
                        <h3>Send, Spend, or Save</h3>
                        <p>Send money to loved ones, pay for goods and services, or save for future transactions.</p>
                         </div>
                         <img src='/img/stablecoin/i2.png' className='mobile-imagehow'/>
                    </div>
                );
            case 'cashout':
                return (
                    <div className={styles.rightContent}>
                        <div className="text-contenthow">
                        <div className={styles.iconContainer}>
                        <img src='/img/stablecoin/coin.svg' className='globe-iconhow'/>
                        </div>
                        <h3>Cash Out with Ease</h3>
                        <p>Off-ramp directly to cash or to a local bank account through our network of partners. Non-Lipa users can also withdraw using shared links.</p>
                        </div>
                        <img src='/img/stablecoin/i3.png' className='mobile-imagehow'/>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>How it works</h2>
                <p>Effortless Gifting in Just Five Easy Steps</p>
            </div>
            <div className={styles.content}>
                <div className={styles.leftMenu}>
                    <div 
                        className={`${styles.menuItem} ${activeSection === 'topup' ? styles.active : ''}`}
                        onClick={() => setActiveSection('topup')}
                    >
                        <img src='/img/stablecoin/import.svg' className='globe-iconhow'/>
                        <div>
                            <h3>Top-Up Your Wallet</h3>
                            <p>Add funds using USDC.</p>
                        </div>
                    </div>
                    <div 
                        className={`${styles.menuItem} ${activeSection === 'send' ? styles.active : ''}`}
                        onClick={() => setActiveSection('send')}
                    >
                        <img src='/img/stablecoin/discover.svg' className='globe-iconhow'/>
                        <div>
                            <h3>Send, Spend, or Save</h3>
                            <p>Send money to loved ones, pay for goods and services, or save for future transactions.</p>
                        </div>
                    </div>
                    <div 
                        className={`${styles.menuItem} ${activeSection === 'cashout' ? styles.active : ''}`}
                        onClick={() => setActiveSection('cashout')}
                    >
                        <img src='/img/stablecoin/coin.svg' className='globe-iconhow'/>
                        <div>
                            <h3>Cash Out with Ease</h3>
                            <p>Off-ramp directly to cash or to a local bank account through our network of partners. Non-Lipa users can also withdraw using shared links.</p>
                        </div>
                    </div>
                </div>
                {renderRightContent()}
            </div>
        </div>
    );
}

export default HowItWorks;