import React from 'react';
import styles from './NonLipaworldUsers.module.css';

const NonLipaworldUsers = () => {
  const handleWaitlist = () => {
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfV3yVqbi-mTCriuNTjCQRGh4Qt_hYgDdj5kAXGxbABN0s-xQ/viewform';
  };
  return (
    <div className={styles.nonLipaContainer}>
      <div className={styles.contentSection} data-aos="fade-up">
        <h1 className={styles.nonLipaTitle}>Non-Lipaworld Users? No Problem!</h1>
        <p className={styles.nonLipaDescription}>
          If you've received a payment and don't have a Lipaworld account, simply:
        </p>
        <ul className={styles.nonLipaList}>
          <li>Click the cash-out link shared with you.</li>
          <li>Follow the instructions to access your funds through our trusted partners.</li>
        </ul>
        <button className={styles.nonLipaButton} onClick={handleWaitlist}>Join waitlist</button>
      </div>

      <div className={styles.phoneContainer} data-aos="fade-down">
        <div className={styles.phoneFrame}>
          <img src='/img/stablecoin/generate.png'/>
        </div>
      </div>
    </div>
  );
};

export default NonLipaworldUsers;