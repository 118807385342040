import React from 'react';
import styles from './ReceiveMoney.module.css';

const ReceiveMoney = () => {
  return (
    <div className={styles.receiveMoneyContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.textSection} data-aos="fade-up">
          <h2 className={styles.title}>
            Receive Money Across Major Bank Accounts and Mobile Wallets
          </h2>
          
          <p className={styles.description}>
            With our P2P Stablecoin Wallet, receiving money has never been easier. You can seamlessly receive funds from across the globe to your wallet, and access them through major bank accounts or mobile wallets of your choice. Whether you're in the US, Europe, Africa, or elsewhere, we support withdrawals in various bank accounts and mobile payment platforms to make transferring funds simple and efficient.
          </p>

          {/* <button className={styles.waitlistButton}>
            Join the waitlist
          </button> */}
        </div>

        <div className={styles.imageSection} data-aos="fade-left">
          <div className={styles.imageContainer}>
            <img 
              src="/img/stablecoin/image.png" 
              alt="Happy user receiving money" 
              className={styles.userImage}
            />
            {/* <div className={styles.transactionCard}>
              <div className={styles.checkIcon}></div>
              <div className={styles.transactionText}>Transaction Successful!</div>
              <div className={styles.amount}>$50.00</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveMoney;