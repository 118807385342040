import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Sidebar from '../sideBar';
import styles from './recipient.module.css';
import { useAuth } from '../../../context';
import CreateRecipientModal from '../../checkout/recipient/CreateRecipientModal';
import EditRecipientModal from '../../checkout/recipient/EditRecipientModal';

const RecipientModal = ({ recipient, onClose }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalTitle}>Recipient Information</h2>
            <p className={styles.modalSubtitle}>View your recipient information</p>
          </div>
          <button className={styles.modalClose} onClick={onClose}>
            x
          </button>
        </div>

        <div className={styles.modalBody}>
          {/* <div className={styles.sendMoneySection}>
            <span className={styles.sendIcon}>💳</span>
            <span>Send money</span>
            <button className={styles.moreButton}>⋮</button>
          </div> */}

          <div className={styles.recipientDetailsSection}>
            {/* <h3 className={styles.sectionTitle}>Recipient Details</h3> */}
            <div className={styles.detailsGrid}>
              <div className={styles.detailItem}>
                <span className={styles.label}>Name</span>
                <div className={styles.nameTag}>
                  {/* <span className={styles.avatarSmall}>{recipient.name?.substring(0, 2).toUpperCase()}</span> */}
                  <span>{recipient.name + ' ' + recipient.surname}</span>
                </div>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Country</span>
                <span>{recipient.country}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Email</span>
                <span>{recipient.email}</span>
              </div>

              <div className={styles.detailItem}>
                <span className={styles.label}>Phone number</span>
                <div className={styles.phoneContainer}>
                  {/* <span className={styles.flag}>{recipient.country}</span> */}
                  <span>{recipient.mobileNumber}</span>
                </div>
              </div>

              <div className={styles.detailItem}>
                <span className={styles.label}>Meter number</span>
                <span>{recipient.meterNumber ?? '-'}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Student number</span>
                <span>{recipient.studentNumber ?? '-'}</span>
              </div>
              <div className={styles.detailItem}>
                <span className={styles.label}>Date</span>
                <span>{recipient.dateAdded}</span>
              </div>
              {recipient.cowryPhoneNumber && (
                <div className={styles.detailItem}>
                  <span className={styles.label}>Cowry phone number</span>
                  <span>{recipient.cowryPhoneNumber}</span>
                </div>
              )}
              {recipient.streetAddress && (
                <div className={styles.detailItem}>
                  <span className={styles.label}>Street address</span>
                  <span>{recipient.streetAddress}</span>
                </div>
              )}
              {recipient.suburb && (
                <div className={styles.detailItem}>
                  <span className={styles.label}>Suburb</span>
                  <span>{recipient.suburb}</span>
                </div>
              )}
              {recipient.city && (
                <div className={styles.detailItem}>
                  <span className={styles.label}>City</span>
                  <span>{recipient.city}</span>
                </div>
              )}
            </div>
          </div>

          <div className={styles.transactionSection}>
            <h3 className={styles.sectionTitle}>Transaction History</h3>
            <div className={styles.transactionList}>
              {recipient.transactions?.map((transaction) => (
                <div key={transaction.id} className={styles.transactionItem}>
                  <div className={styles.transactionInfo}>
                    <span className={styles.sendIcon}>{transaction.type === 'sent' ? '↗️' : '↙️'}</span>
                    <span>{transaction.description}</span>
                  </div>
                  <span className={styles.transactionDate}>{transaction.date}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Recipient = () => {
  const [activeTab, setActiveTab] = useState('Recipients');
  const [showDropdown, setShowDropdown] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [error, setError] = useState(null);
  const { authState } = useAuth();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['recipients', authState?.id],
    queryFn: async () => {
      const token = localStorage.getItem('token');
      if (!token || !authState?.id) {
        throw new Error('Authentication required');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipients?userId=${authState.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data.data;
    },
    enabled: !!authState?.id,
  });

  const recipients = data?.recipients || [];
  const totalRecipients = data?.total || 0;

  const handleAddRecipient = (newRecipient) => {
    refetch(); // Refetch the recipients list after adding a new one
  };

  const handleCreateRecipient = () => {
    setModalType('create');
    setSelectedRecipient(null);
  };

  // const handleEditRecipient = (recipient) => {
  //   setModalType("edit");
  //   setSelectedRecipient(recipient);
  // };

  const handleModalClose = () => {
    setModalType(null);
    setSelectedRecipient(null);
  };

  // const handleFormSubmit = async (data, isEdit) => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const endpoint = isEdit
  //       ? `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipients/${recipients.id}`
  //       : `${process.env.REACT_APP_API_RECIPIENTS_URL}/recipients`;

  //     const method = isEdit ? 'put' : 'post';
  //     await axios[method](endpoint, data, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     fetchRecipients();
  //     handleModalClose();
  //   } catch (err) {
  //     setError('Failed to submit form');
  //   }
  // };

  const handleDropdown = (id) => {
    setShowDropdown(showDropdown === id ? null : id);
  };

  const handleViewDetails = (recipient) => {
    setSelectedRecipient(recipient);
    setShowDropdown(null);
  };

  return (
    <div className={styles.recipientContainer}>
      <div className={styles.tabsContainer}>
        <button
          className={`${styles.tab} ${activeTab === 'Recipients' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Recipients')}
        >
          Recipients
        </button>
      </div>

      <div className={styles.recipientHeader}>
        {recipients.length > 0 ? (
          <div className={styles.recipientCount}>
            Recipients <span className={styles.countBadge}>({recipients.length})</span>
          </div>
        ) : (
          <div className={styles.recipientCount}>No recipients found</div>
        )}

        <div className={styles.recipientActions}>
          <button className={styles.createButton} onClick={handleCreateRecipient}>
            <span>
              <img src="/img/profile/add.svg" />
            </span>{' '}
            Create new recipient
          </button>
        </div>
      </div>

      {isLoading && <div className={styles.loading}>Fetching your recipients...</div>}
      <div className={styles.recipientsList}>
        {!isLoading &&
          recipients.map((recipient) => (
            <div key={recipient.id} className={styles.recipientCard}>
              <div className={styles.recipientInfo}>
                <div className={styles.avatarCircle}>
                  {`${recipient?.name?.[0].toUpperCase() || ''}${recipient?.surname?.[0].toUpperCase() || ''}`}
                </div>
                <div className={styles.recipientDetails}>
                  <h3 className={styles.recipientName}>{recipient.name + ' ' + recipient.surname}</h3>
                  <div className={styles.recipientContact}>
                    <span className={styles.recipientEmail}>{recipient.email}</span>
                    <div className={styles.phoneContainer}>
                      <span className={styles.flag}>{recipient.country}</span>
                      <span className={styles.recipientPhone}>{recipient.mobileNumber}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.recipientControls}>
                {/* <button className={styles.sendMoneyButton}>
                  <span className={styles.sendIcon}>
                    <img src="/img/profile/send-sqaure-2.svg" />
                  </span>
                  Send Voucher
                </button> */}
                <div className={styles.dropdownContainer}>
                  <button className={styles.moreButton} onClick={() => handleDropdown(recipient.id)}>
                    ⋮
                  </button>
                  {showDropdown === recipient.id && (
                    <div className={styles.dropdownMenu}>
                      <button className={styles.dropdownItem} onClick={() => handleViewDetails(recipient)}>
                        <span>
                          <img src="/img/profile/frame.svg" />
                        </span>{' '}
                        View details
                      </button>
                      {/* <button
                        className={styles.dropdownItem}
                        onClick={() => handleEditRecipient(recipient)}
                      >
                        <span>
                          <img src="/img/profile/edit.svg" />
                        </span>{" "}
                        Edit details
                      </button>
                      <button
                        className={styles.dropdownItem}
                        onClick={() => handleDeleteRecipient(recipients.id)}
                      >
                        <span>
                          <img src="/img/profile/trash1.svg" />
                        </span>{" "}
                        Delete recipient
                      </button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      {selectedRecipient && <RecipientModal recipient={selectedRecipient} onClose={() => setSelectedRecipient(null)} />}

      {modalType === 'create' && (
        <CreateRecipientModal
          onClose={handleModalClose}
          onSuccess={(newRecipient) => {
            handleAddRecipient(newRecipient);
            handleModalClose();
          }}
        />
      )}
      {modalType === 'edit' && selectedRecipient && (
        <EditRecipientModal
          onClose={handleModalClose}
          recipient={selectedRecipient}
          onSubmit={(data) => handleFormSubmit(data, true)}
        />
      )}
    </div>
  );
};

const RecipientManagement = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Recipient Management</h1>
        <div className={styles.grid}>
          <Recipient />
        </div>
      </main>
    </div>
  );
};

export default RecipientManagement;
