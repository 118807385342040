import React from 'react';
import styles from './VirtualCard.module.css';

const VirtualCard = () => {
  return (
    <div className={styles.virtualCardContainer}>
      <div className={styles.contentWrapper}>
        <div className={styles.cardSection} data-aos="fade-right">
          <div className={styles.cardWrapper}>
              <img src='/img/stablecoin/visalipa.png'/>
          </div>
        </div>

        <div className={styles.contentSection} data-aos="fade-left">
          <h2 className={styles.title}>
            Lipaworld Visa Virtual Card
          </h2>

          <p className={styles.subtitle}>
            Your dollars, your way–anywhere in the world!
          </p>

          <p className={styles.description}>
            We're excited to announce the upcoming launch of the Lipaworld Visa Virtual Card, giving you:
          </p>

          <ul className={styles.featuresList}>
            <li className={styles.featureItem}>
              <span className={styles.featureTitle}>Global Access:</span>
              <span className={styles.featureText}>
                Spend your USDC funds seamlessly at any merchant accepting Visa, whether online or in-store.
              </span>
            </li>
            <li className={styles.featureItem}>
              <strong className={styles.featureTitle}>Full Flexibility:</strong>
              <span className={styles.featureText}>
                From booking flights to shopping online, use your virtual card for everything you need.
              </span>
            </li>
            <li className={styles.featureItem}>
              <span className={styles.featureTitle}>Simple Integration:</span>
              <span className={styles.featureText}>
                Manage your card directly from your Lipaworld wallet, ensuring convenience at your fingertips.
              </span>
            </li>
          </ul>

          <p className={styles.stayTuned}>
            Stay tuned for this game-changing feature!
          </p>

          {/* <button className={styles.waitlistButton}>
            Join the waitlist
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default VirtualCard;