import React, { useState, Suspense, lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styles from './RecipientPage.module.css';
import RecipientSelect from './RecipientSelect';
import CreateRecipientModal from './CreateRecipientModal';
import { useCart, useDispatchCart } from '../../../context/CartContext';
import { useAuth } from '../../../context/AuthContext';

const CartSummary = lazy(() => import('../cartitem/CartSummary'));
const CowryModal = lazy(() => import('../recipient/CowryNumberModal'));

const RecipientPage = () => {
  const { authState } = useAuth();
  const cart = useCart();
  const dispatchCart = useDispatchCart();
  const [showModal, setShowModal] = useState(false);
  const [showCowryModal, setShowCowryModal] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (cart) {
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart]);

  const handleModalClose = (success = false) => {
    setShowModal(false);
    if (success) {
      toast.success('Recipient created successfully! You can now select them from the dropdown.', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    queryClient.invalidateQueries(['recipients', authState?.id]);
  };

  const handleRecipientSelect = (recipientId, productRecipient) => {
    const hasCowryProduct = cart?.items?.some((item) => item.product.isCowryProduct);

    if (hasCowryProduct && !productRecipient?.cowryPhoneNumber) {
      setSelectedRecipient(productRecipient);
      setShowCowryModal(true);
      return;
    }

    if (productRecipient?.data?.data) {
      dispatchCart({
        type: 'UPDATE_CART_RECIPIENTS',
        payload: { recipientId, productRecipient: productRecipient.data.data },
      });
    } else {
      dispatchCart({
        type: 'UPDATE_CART_RECIPIENTS',
        payload: { recipientId, productRecipient },
      });
    }
  };

  const handleContinueToPayment = () => {
    const currentRecipient = cart?.recipients?.[0];
    if (!currentRecipient) {
      toast.error('Please select a recipient');
      return;
    }

    const hasCowryProduct = cart?.items?.some((item) => item.product.isCowryProduct);
    if (hasCowryProduct && !currentRecipient?.cowryPhoneNumber) {
      setSelectedRecipient(currentRecipient);
      setShowCowryModal(true);
      return;
    }

    navigate('/payment');
  };

  return (
    <div className={styles.recipientContainer}>
      <div className={styles.recipientMainSection}>
        <div className={styles.recipientHeader}>
          <h1>Recipient</h1>
          <button className={styles.createNewButton} onClick={() => setShowModal(true)}>
            + Create new recipient
          </button>
        </div>

        <p className={styles.recipientSubtext}>Create a recipient or select from your saved recipients</p>

        <div className={styles.selectSection}>
          <label>Select recipient</label>
          <RecipientSelect onSelect={handleRecipientSelect} />
        </div>
      </div>

      <Suspense fallback={<div className={styles.loading}>Loading cart summary...</div>}>
        <CartSummary buttonText="Continue to Payment" onContinue={handleContinueToPayment} />
      </Suspense>

      {showModal && <CreateRecipientModal onClose={handleModalClose} />}

      {showCowryModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <CowryModal
            onClose={(updatedRecipient) => {
              if (updatedRecipient) {
                dispatchCart({
                  type: 'UPDATE_CART_RECIPIENTS',
                  payload: {
                    recipientId: updatedRecipient.id,
                    productRecipient: updatedRecipient,
                  },
                });
              }
              setShowCowryModal(false);
              setSelectedRecipient(null);
            }}
            recipient={selectedRecipient}
          />
        </Suspense>
      )}
    </div>
  );
};

export default RecipientPage;
