import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context';
import styles from './Sidebar.module.css';

const Sidebar = () => {
  const location = useLocation();
  const { authState, resetAuthState } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    resetAuthState();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/signin');
  };

  const links = [
    { text: 'Account Overview', icon: '/img/profile/element-3.svg', route: '/account-overview' },
    { text: 'Personal Information', icon: '/img/profile/profile.svg', route: '/profile-information' },
    { text: 'Security Settings', icon: '/img/profile/security-safe.svg', route: '/security-settings' },
    { text: 'Voucher Management', icon: '/img/profile/wallet.svg', route: '/voucher-management' },
    { text: 'Recipient Management', icon: '/img/profile/heart.svg', route: '/recipient-management' },
    // { text: 'Payment Methods', icon: '/img/profile/wallet-2.svg', route: '/payment-methods' },
    { text: 'Contact Us', icon: '/img/profile/24-support.svg', route: '/contact' },
  ];

  return (
    <div className={styles.sidebar}>
      <div className={styles.profileHeader}>
        <div className={styles.avatar}>{`${authState?.firstName?.[0] || ''}${authState?.lastName?.[0] || ''}`}</div>
        <div>
          <h2 className={styles.header}>{`${authState?.firstName || ''} ${authState?.lastName || ''}`}</h2>
          <span className={styles.tierBadge}>Tier {authState?.personaStatus === 'approved' ? '2' : '1'}</span>
        </div>
      </div>

      <nav className={styles.sidebarNav}>
        {links.map((link) => (
          <Link key={link.text} to={link.route} className={location.pathname === link.route ? styles.active : ''}>
            <span>
              <img src={link.icon} alt={link.text} />
            </span>
            {link.text}
          </Link>
        ))}
      </nav>

      <button className={styles.signOutButton} onClick={handleSignOut}>
        <span>
          <img src="/img/profile/logout.svg" alt="Sign Out" />
        </span>{' '}
        Sign Out
      </button>
    </div>
  );
};

export default Sidebar;
