import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Header from '../components/Header';
import MoneyTransfer from '../components/stable-coin/MoneyTransfer';
import WalletFeatures from '../components/stable-coin/WalletFeatures';
import SupportedCountries from '../components/stable-coin/SupportedCountries';
import ReceiveMoney from '../components/stable-coin/ReceiveMoney';
import VirtualCard from '../components/stable-coin/VirtualCard';
import NonLipaworldUsers from '../components/stable-coin/NonLipaworldUsers';
import HowItWorks from '../components/stable-coin/HowItWorks';
import Impact from '../components/stable-coin/Impact';
import FAQCoin from '../components/stable-coin/FaqCoin';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';

const StableCoin = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
        title: window.location.title,
      });
    }
  }, []);

  return (
    <div className="min-h-screen ">
      <div className="container mx-auto px-4">
        <Header />
        <main>
          <MoneyTransfer />
          <WalletFeatures />
          <SupportedCountries />
          <ReceiveMoney />
          <VirtualCard />
          <NonLipaworldUsers />
          <HowItWorks />
          <FAQCoin />
          <Testimonials />
          <Impact />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default StableCoin;
