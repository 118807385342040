const faqDataCoin = [
    {
      category: 'General Information',
      questions: [
        {
          summary: 'What is the Lipaworld Stablecoin Wallet?',
          details:
            'The Lipaworld Stablecoin Wallet allows users to store, send, and receive stablecoins securely and efficiently. It is designed to provide a stable, low-volatility cryptocurrency solution, ideal for cross-border transactions and international transfers. Our wallet supports USDC exclusively, a leading stablecoin pegged to the US Dollar, ensuring value stability.',
        },
        {
          summary: 'How does the Lipaworld Stablecoin Wallet work?',
          details:
            'Our wallet securely holds USDC stablecoin which is a cryptocurrency pegged to a stable asset like the US Dollar. This ensures your balance maintains consistent value, making it a reliable option for everyday use and cross-border transfers.',
        },
        {
          summary: 'Can I send money to a bank account or mobile wallet?',
          details:
            'Yes! You can send funds directly to major bank accounts or mobile wallets across different regions. Whether your recipient uses a traditional bank or mobile money services, the Lipaworld Stablecoin Wallet ensures easy and flexible transfers.',
        },
      ],
    },
    {
      category: 'Account and Registration',
      questions: [
        {
          summary: 'How can I receive money with the Lipaworld Stablecoin Wallet?',
          details:
            'You can receive money through your wallet by simply sharing your wallet address with the sender or by adding funds in local currency. Funds can be received from across the globe to your wallet and can be accessed through connected bank accounts or mobile wallets.',
        },
        {
          summary: 'Is the Lipaworld Stablecoin Wallet secure?',
          details:
            'Yes! The wallet uses advanced security protocols, including encryption, two-factor authentication (2FA), and biometric verification (if supported) to ensure the safety of your funds. Additionally, our wallet is regulated, giving you peace of mind with industry-standard security practices.',
        },
        {
          summary: 'Can I use the Lipaworld Stablecoin Wallet for international transfers?',
          details: 'Absolutely! The Lipaworld Stablecoin Wallet is perfect for cross-border transactions. It provides an affordable, efficient way to send money internationally without the delays and fees typically associated with traditional banking or remittance services.',
        },
      ],
    },
  
    {
      category: 'Sending Vouchers',
      questions: [
        {
          summary: 'Are there any fees for using the Lipaworld Stablecoin Wallet?',
          details: `There are minimal fees for transaction processing, which are typically lower than those associated with traditional banking methods. You’ll always know the fee upfront before confirming a transaction.`,
        },
        {
          summary: ' Which currencies can I use with the Lipaworld Stablecoin Wallet?',
          details:
            'The wallet supports stablecoins pegged to popular fiat currencies like the US Dollar (USD), Euro (EUR), and others, including USDC. You can easily convert between stablecoins and other supported currencies in the wallet.',
        },
        {
          summary: `How do I add funds to my Lipa World Stablecoin Wallet?`,
          details:
            'You can add funds to your wallet by transferring stablecoins from another wallet or buying stablecoins directly through supported payment methods within the wallet interface.',
        },
        {
          summary: 'Can I access my wallet on multiple devices?',
          details: 'Yes! The Lipaworld Stablecoin Wallet is designed to sync across multiple devices, allowing you to access your funds on both desktop and mobile platforms for seamless use anywhere.',
        },
      ],
    },
  
    {
      category: 'Payment and Fees',
      questions: [
        {
          summary: 'How can I withdraw funds from the Lipaworld Stablecoin Wallet?',
          details:
            'Funds can be withdrawn to your linked bank account or mobile wallet. Simply follow the withdrawal process in the app and select your preferred method.',
        },
        {
          summary: 'What should I do if I lose access to my Lipaworld Stablecoin Wallet?',
          details:
            'If you lose access to your wallet, please contact our customer support team immediately. We have secure recovery procedures in place to help you regain access to your wallet.',
        },
      ],
    },
    {
      category: 'Receiving and Redeeming Vouchers',
      questions: [
        {
          summary: ' Is the Lipaworld Stablecoin Wallet available in my country?',
          details: `The wallet is available in multiple countries, and we are continuously expanding our services. Check the list of supported regions or contact customer support to confirm availability in your area.`,
        },
        {
          summary: 'Can I use the wallet for business transactions?',
          details: 'Yes! The Lipaworld Stablecoin Wallet can be used for both personal and business transactions. It offers a fast, reliable solution for businesses involved in international trade, remittances, or digital payments.',
        },
        {
          summary: 'Is the Lipaworld Stablecoin Wallet regulated?',
          details:
            'Yes, the Lipa World Stablecoin Wallet complies with applicable regulations, ensuring that it meets industry standards for security and operational transparency. We are committed to providing a regulated and secure service for our users.',
        },
      ],
    },
  ];
  
  export default faqDataCoin;
  
  