import React from 'react';
import { Link } from 'react-router-dom';

const Fostering = () => {
  return (
    <div className="fostering-container">
      <div className="image-section" data-aos="fade-down">
        <img
          src="/img/blog/mancard.png"
          alt="crossroads and lipaworld"
          className="community-image"
        />
      </div>
      <div className="content-section" data-aos="fade-right">
        <div className="category-and-title">
          <div className="metadata">
            <span className="category">Article</span>
            <span className="separator">•</span>
            <span className="read-time">17min read</span>
            <span className="separator">•</span>
            <span className="date">January 20, 2025</span>
          </div>
          <h2 className="title">Your Guide to Unlocking US Banking: A Step-by-Step Approach
          </h2>
        </div>
        <p className="description">
        Landing in the United States is an exciting adventure, but...
        </p>
        <div className="author-section">
          {/* <img
            src="/img/blog/rev1.png"
            alt="Lipaworld"
            className="avatar"
          /> */}
          <span className="author-name">Lipaworld</span>
        </div>
        <Link to='/blog/unlocking-us-banking' className="read-more-button"> <button className="read-more-button">Read more</button></Link>
      </div>
    </div>
  );
};

export default Fostering;
