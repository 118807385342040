import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, User } from 'lucide-react';
import { useAuth } from '../context';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const { authState } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActivePath = (path) => {
    return currentPath === path ? 'text-green-600' : 'text-gray-600 hover:text-green-600';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { path: '/', label: 'Home' },
    { path: '/marketplace', label: 'Shop' },
    { path: '/partners', label: 'Partners' },
    { path: '/about-us', label: 'About Us' },
    { path: '/send-money', label: 'Send Money' },
    { path: '/blog', label: 'Blog' },
    { path: '/contact-us', label: 'Contact Us' },
  ];

  const AuthButtons = () => {
    if (authState.isAuthenticated) {
      return (
        <Link 
          to="/account-overview" 
          className="flex items-center space-x-2 text-gray-600 hover:text-green-600"
        >
          <User className="h-5 w-5" />
          <span className="text-sm font-medium">Profile</span>
        </Link>
      );
    }

    return (
      <>
        <Link to="/signin" className="text-gray-600 hover:text-green-600 text-sm font-medium">
          Log In
        </Link>
        <Link
          to="/signup"
          className="bg-green-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-green-700 transition-colors duration-200"
        >
          Register
        </Link>
      </>
    );
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 bg-white transition-all duration-300 ${
        isScrolled ? 'shadow-md' : 'shadow-sm'
      }`}
    >
      <div className="mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0 lipalogo">
            <Link to="/" className="text-2xl font-bold text-green-600">
              <img src="/img/header/logo-light.png" alt="Lipaworld" className="h-8" />
            </Link>
          </div>

          <nav className="hidden md:flex space-x-8">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`${isActivePath(item.path)} transition-colors duration-200 text-sm font-medium`}
              >
                {item.label}
              </Link>
            ))}
          </nav>

          <div className="hidden md:flex items-center space-x-4">
            <AuthButtons />
          </div>

          <div className="flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-green-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
          <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl">
            <div className="flex justify-end p-4">
              <button
                onClick={toggleMenu}
                className="text-gray-600 hover:text-green-600 focus:outline-none"
                aria-label="Close menu"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <nav className="px-4 py-2">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`block py-2 text-base font-medium ${isActivePath(item.path)}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </Link>
              ))}
              <div className="border-t border-gray-200 mt-4 pt-4">
                {authState.isAuthenticated ? (
                  <Link
                    to="/account-overview"
                    className="block py-2 text-base font-medium text-gray-600 hover:text-green-600"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Profile
                  </Link>
                ) : (
                  <>
                    <Link
                      to="/signin"
                      className="block py-2 text-base font-medium text-gray-600 hover:text-green-600"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Log In
                    </Link>
                    <Link
                      to="/signup"
                      className="block py-2 text-base font-medium text-gray-600 hover:text-green-600"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Register
                    </Link>
                  </>
                )}
              </div>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;