import React, { useState, useEffect } from 'react';
import { useAuth } from '../context';
import { Eye, EyeOff } from 'lucide-react';
import styles from '../styles/signup.module.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

const SigninForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { updateAuthState } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const access_token = urlParams.get('access_token');
    const error = urlParams.get('error');
    const errorDescription = urlParams.get('error_description');

    if (access_token) {
      navigate('/marketplace');
    } else if (error || errorDescription) {
      const decodedError = decodeURIComponent(errorDescription || error);

      if (decodedError.includes('Attribute cannot be updated')) {
        toast.error('This email is already associated with an account. Please sign in instead.');
      } else {
        toast.error(decodedError || 'Google authentication failed');
      }

      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [location, navigate]);

  const handleInputChange = (e) => {
    if (e.target.name === 'email') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000);

    try {
      const authUrl = process.env.REACT_APP_API_USERS_URL;
      const response = await fetch(`${authUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = (data.error || data.message || '').toLowerCase();
        if (
          errorMessage.includes('user is not confirmed') ||
          errorMessage.includes('email not verified') ||
          errorMessage.includes('not verified')
        ) {
          try {
            const verifyResponse = await fetch(`${authUrl}/resend-code`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: formData.email }),
            });

            if (verifyResponse.ok) {
              toast.error('Please complete your email verification.');
              setTimeout(() => {
                navigate('/emailverify');
              }, 2000);
              return;
            } else {
              const verifyError = await verifyResponse.json();
              console.error('Error resending verification email:', verifyError);
              toast.error(verifyError.error || 'Failed to resend verification email.');
            }
          } catch (err) {
            console.error('Error during email verification process:', err);
            toast.error('An error occurred while resending verification email.');
          }
        }
        throw new Error(data.error || data.message || 'Sign in failed');
      }

      localStorage.setItem('token', data.accessToken);

      updateAuthState({
        isAuthenticated: true,
        id: data.user.id,
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumber: data.user.phoneNumber,
        userType: data.user.userType,
        indexNumber: data.user.indexNumber,
        createdAt: data.user.createdAt,
        updatedAt: data.user.updatedAt,
        personaStatus: data.user.personaStatus,
        personaInquiryId: data.user.personaInquiryId,
      });

      toast.success('Sign in successful');
      navigate('/marketplace');
    } catch (error) {
      const errorMessage =
        error.name === 'TypeError'
          ? 'Unable to connect to the server. Please check your internet connection.'
          : error.message;

      toast.error(errorMessage);
      console.error('Sign in error:', error);
    } finally {
      setLoading(false);
    }
  };

  // const handleGoogleAuth = () => {
  //   const baseUrl = process.env.REACT_APP_BASE_URL;
  //   const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  //   const authUrl = process.env.REACT_APP_AUTH_URL;
  //   const url = `${authUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${baseUrl}/marketplace&response_type=token&client_id=${googleClientId}`;
  //   window.location.href = url;
  // };

  return (
    <div className={styles.container}>
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 5000,
          style: {
            background: '#333',
            color: '#fff',
          },
        }}
      />

      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src="/img/auth/logo-black2.png" alt="Lipaworld logo" className={styles.logo} />
          </Link>
        </div>

        <div className={styles.welcomeText}>
          <h1>Welcome Back to Lipaworld!</h1>
          <p>Log in to access your account and continue sending value back home.</p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="password">Password</label>
            <div className={styles.passwordInput}>
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <button type="button" onClick={() => setShowPassword(!showPassword)} className={styles.passwordToggle}>
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          <button type="submit" className={styles.signupButton} disabled={loading}>
            {loading ? 'Signing in...' : 'Sign In'}
          </button>

          <div className={styles.bottomLinks}>
            <p className={styles.signin}>
              Forgot Password? <a href="/forgot-password">Reset</a>
            </p>
            <p className={styles.signin}>
              Don't have an account? <a href="/signup">Sign up here</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SigninForm;
