import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context';
import Sidebar from '../sideBar';
import styles from './profileInfo.module.css';

const EditNameModal = ({ onClose, onSave }) => {
  const [firstName, setFirstName] = useState('Newman');
  const [lastName, setLastName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ firstName, lastName });
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalTitle}>Edit Name</h2>
            <p className={styles.modalSubtitle}>Edit the fields and press continue to confirm</p>
          </div>
          <button className={styles.modalClose} onClick={onClose}>
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} className={styles.modalBody}>
          <div className={styles.formFields}>
            <div className={styles.inputField}>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
              />
            </div>
            <div className={styles.inputField}>
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className={styles.modalActions}>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className={styles.continueButton}>
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ProfileInfo = () => {
  const [showNameModal, setShowNameModal] = useState(false);
  const { authState } = useAuth();
  const navigate = useNavigate();

  const handleSaveName = (nameData) => {
    setShowNameModal(false);
  };

  const handleAddNumber = () => {
    navigate('/addphonenumber');
  };

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileCard}>
        <h1 className={styles.cardTitle}>Profile Information</h1>
        <div className={styles.profileContent}>
          <div className={styles.avatarSection}>
            <div className={styles.avatarCircle}>
              <span>{`${authState?.firstName?.[0] || ''}${authState?.lastName?.[0] || ''}`}</span>
            </div>
            {/* <div className={styles.uploadSection}>
              <button className={styles.uploadButton}>Upload Picture</button>
              <button className={styles.deleteButton}>
                <span className={styles.deleteIcon}><img src="/img/profile/trash.svg"/></span>
              </button>
            </div> */}
          </div>

          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <div className={styles.infoField}>
                <label className={styles.fieldLabel}>Name</label>
                <div className={styles.fieldContent}>
                  <span className={styles.fieldValue}>{`${authState?.firstName || ''} ${
                    authState?.lastName || ''
                  }`}</span>
                  {/* <button className={styles.changeButton} onClick={() => setShowNameModal(true)}>Change Name</button> */}
                </div>
              </div>
            </div>

            {showNameModal && <EditNameModal onClose={() => setShowNameModal(false)} onSave={handleSaveName} />}

            <div className={styles.infoRow}>
              <div className={styles.infoField}>
                <label className={styles.fieldLabel}>Email Address</label>
                <div className={styles.fieldContent}>
                  <span className={styles.fieldValue}>{authState?.email || 'No email provided'}</span>
                  <span className={styles.verifiedBadge}>Verified</span>
                </div>
              </div>
            </div>

            <div className={styles.infoRow}>
              <div className={styles.infoField}>
                <label className={styles.fieldLabel}>Phone number</label>
                <div className={styles.fieldContent}>
                  <div className={styles.phoneNumber}>
                    <span className={styles.flag}>{authState?.countryFlag}</span>
                    <span className={styles.fieldValue}>{authState?.phoneNumber || 'No phone provided'}</span>
                  </div>
                  <div className={styles.actionButtons}>
                    {/* <span className={styles.unverifiedBadge}>Unverified</span> */}
                    {!authState?.phoneNumber ? (
                      <>
                        <button className={styles.changeButton} onClick={handleAddNumber}>
                          Add Number
                        </button>
                      </>
                    ) : (
                      <span className={styles.unverifiedBadge}>Unverified</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.infoRow}>
              <div className={styles.infoField}>
                <label className={styles.fieldLabel}>Country</label>
                <div className={styles.fieldContent}>
                  <div className={styles.country}>
                    {/* <span className={styles.flag}>{authState?.countryFlag || '🌍'}</span> */}
                    <span className={styles.fieldValue}>{authState?.country || 'null'}</span>
                  </div>
                  {/* <button className={styles.changeButton}>
                    Change Country
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalInfo = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate('/signin');
    }
  }, [authState.isAuthenticated, navigate]);

  if (!authState || !authState.isAuthenticated) {
    return <div>Loading...</div>;
  }
  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={styles.mainContent}>
        <h1 className={styles.h1}>Personal Information</h1>
        <div className={styles.grid}>
          <ProfileInfo />
        </div>
      </main>
    </div>
  );
};

export default PersonalInfo;
