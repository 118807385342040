import calculatePaymentItemAmount from './calculatePaymentItemAmount';

const mapOrderItems = (cartItems, rate, transaction) => {
  const totalDiscount = transaction?.cartDiscounts || 0;
  let remainingDiscount = totalDiscount;

  const orderItems = cartItems.map((item) => {
    const itemAmount = calculatePaymentItemAmount(item, rate);
    const maxDiscount = Math.min(itemAmount, remainingDiscount);
    const adjustedAmount = itemAmount - maxDiscount;
    remainingDiscount -= maxDiscount;

    return {
      name: item.deal.voucherName,
      quantity: item.quantity,
      unit_amount: {
        currency_code: 'USD',
        value: Number(adjustedAmount).toFixed(2),
      },
      description: `dealId:${item.deal.dealId}`,
      category: 'DIGITAL_GOODS',
    };
  });

  const additionalItems = [
    {
      name: 'Administration Fee',
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: Number(transaction?.processingFee || 0).toFixed(2),
      },
      category: 'DIGITAL_GOODS',
    },
    {
      name: 'Transaction Fee',
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: Number(transaction?.transactionFee || 0).toFixed(2),
      },
      category: 'DIGITAL_GOODS',
    },
    {
      name: 'Delivery Fee',
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: Number(transaction?.deliveryFee || 0).toFixed(2),
      },
      category: 'DIGITAL_GOODS',
    },
  ];

  return [...orderItems, ...additionalItems];
};

export default mapOrderItems;
