import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { toast } from 'react-toastify';

import CartSummary from './CartSummary';
import { useCart, useDispatchCart } from '../../../context/CartContext';
import { useTransaction, useDispatchTransaction } from '../../../context/TransactionContext';
import { useRecipients } from '../../../context/RecipientsContext';
import calculateProcessingFee from '../../../utils/calculateProcessingFee';

import styles from './cartitem.module.css';

const Cart = (props) => {
  const { exchangeRate, verificationStatus } = props;
  const navigate = useNavigate();
  const { authState } = useAuth();
  const { cartItems } = useCart();
  const dispatchCart = useDispatchCart();

  const dispatchTransaction = useDispatchTransaction();
  const recipients = useRecipients();
  const cart = useCart();
  const transaction = useTransaction();

  const [cartTotalAmount, setCartTotalAmount] = useState('0.00');
  const [cartTransactionFee, setCartTransactionFee] = useState('0.00');
  const [cartProcessingFee, setCartProcessingFee] = useState('0.00');
  const [cartDiscounts, setCartDiscounts] = useState(0);
  const [cartTotalAmountNumeric, setCartTotalAmountNumeric] = useState(0);
  const [totalCowryValue, setTotalCowryValue] = useState(0);
  const [cartSubtotal, setCartSubtotal] = useState('0.00');
  const [deliveryFee, setDeliveryFee] = useState('0.00');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate('/signin');
    }
  }, [authState.isAuthenticated, navigate]);

  const [searchParams, setSearchParams] = useState(() => {
    return new URLSearchParams(window.location.search);
  });

  useEffect(() => {
    if (cart && cart.cartItems) {
      setErrorMessage(null);
    }
  }, [cart]);

  useEffect(() => {
    if (cart && cart.cartItems) {
      if (cart.cartItems.length === 0) {
        // mixpanel.track(`Empty Cart`);
        setErrorMessage(`Your cart is empty.`);
        return;
      }
    }
  }, [cart?.cartItems, cart]);

  const getRedemptionAmount = (item) => {
    if (item.deal.customAmount && item.redemptionInputValue) {
      return item?.redemptionInputValue * 100;
    } else {
      return item.deal.redemptionValues[0];
    }
  };

  useEffect(() => {
    if (cart?.cartItems && exchangeRate?.markedup_rate) {
      let totalTransactionFee = 0;
      let totalDeliveryFee = 0;
      const subTotalAmount = cart.cartItems.reduce((acc, item) => {
        let itemTotal = 0;
        const fxRate = item.deal?.usdAmount ? 1 : exchangeRate?.markedup_rate;

        if (item.deal?.usdAmount !== null && item.deal?.usdAmount !== undefined && item.deal?.usdAmount > 0) {
          itemTotal = item.quantity * item.deal.usdAmount;
        } else if (item.deal.redemptionCurrency === 'USD') {
          const redemptionAmount = getRedemptionAmount(item) || 0;
          itemTotal = item.quantity * (redemptionAmount / 100);
        } else {
          const redemptionAmount = getRedemptionAmount(item) || 0;
          itemTotal = (item.quantity * (redemptionAmount / 100)) / (fxRate || 1);
        }
        // if this item has item.deliveryFee, add it to the total delivery fee
        if (item.deliveryFee) {
          totalDeliveryFee += item.deliveryFee;
        }
        const transactionFeeDecimal = Number(item.deal.transactionFee);
        const transactionFeeItem = itemTotal * transactionFeeDecimal;
        totalTransactionFee += transactionFeeItem;
        return acc + itemTotal;
      }, 0);

      setDeliveryFee(totalDeliveryFee.toFixed(2));
      setCartSubtotal(subTotalAmount.toFixed(2));
      const transactionFeeAmount = totalTransactionFee.toFixed(2);
      setCartTransactionFee(transactionFeeAmount);

      const processingFee = calculateProcessingFee(Number(subTotalAmount), totalTransactionFee, cart.cartItems);
      setCartProcessingFee(processingFee);

      const totalAmount =
        Number(subTotalAmount) +
        Number(transactionFeeAmount) +
        Number(deliveryFee) +
        Number(processingFee) -
        cartDiscounts;

      if (totalAmount > 9999) {
        setErrorMessage('The total amount is over $9,999. Please remove some items from the cart to continue.');
        return;
      }
      setCartTotalAmountNumeric(totalAmount);
      setCartTotalAmount(totalAmount.toFixed(2));

      sessionStorage.setItem('cartTotalAmount', JSON.stringify(totalAmount));
      sessionStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart, exchangeRate?.markedup_rate, cartDiscounts]);

  useEffect(() => {
    dispatchCart({
      type: 'UPDATE_CART_AMOUNTS',
      payload: {
        subtotal: cartSubtotal,
        totalAmount: cartTotalAmount,
        transactionFee: cartTransactionFee,
        processingFee: cartProcessingFee,
        deliveryFee: deliveryFee,
        discounts: cartDiscounts,
      },
    });
    updateTransactionAmounts(
      cartProcessingFee,
      cartTotalAmount,
      cartTransactionFee,
      cartDiscounts,
      cartSubtotal,
      deliveryFee,
    );
  }, [cartSubtotal, cartTotalAmount, cartTransactionFee, cartProcessingFee, cartDiscounts, deliveryFee]);

  useEffect(() => {
    sessionStorage.setItem('recipients', JSON.stringify(recipients));
  }, [recipients]);

  const updateTransactionAmounts = (processFee, cartTotalAmount, cartTransactionFee, cartDiscounts, subtotal) => {
    dispatchTransaction({
      type: 'UPDATE_AMOUNTS',
      payload: {
        subtotal: Number(subtotal),
        processingFee: Number(processFee),
        deliveryFee: Number(deliveryFee),
        cartTotalAmount: Number(cartTotalAmount),
        transactionFee: Number(cartTransactionFee),
        cartDiscounts: cartDiscounts,
      },
    });
  };

  const updateRecipients = (recipients, user) => {
    if (recipients.length > 0) {
      if (recipients[0].id) {
        dispatchTransaction({
          type: 'UPDATE_PARTIES',
          payload: {
            senderId: recipients[0].senderId,
            recipientIds: [recipients[0].id],
          },
        });
      } else {
        dispatchTransaction({
          type: 'UPDATE_PARTIES',
          payload: {
            senderId: user?.id ?? '',
            recipientIds: [`${recipients[0].name} ${recipients[0].surname} ${recipients[0].mobileNumber}`],
          },
        });
      }
    }
  };

  const removeFromCartHandler = (cartItemId) => {
    try {
      if (cartItemId) {
        dispatchCart({
          type: 'REMOVE_CART_ITEM',
          payload: cartItemId,
        });
        setCartDiscounts(0);
      } else {
        setErrorMessage('Missing important order details.');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    sessionStorage.setItem('transaction', JSON.stringify(transaction));
  }, [transaction]);

  useEffect(() => {
    sessionStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const checkoutHandler = () => {
    updateTransactionAmounts(
      cartProcessingFee,
      cartTotalAmount,
      cartTransactionFee,
      cartDiscounts,
      cartSubtotal,
      deliveryFee,
    );
    sessionStorage.setItem('cart', JSON.stringify(cart));
    updateRecipients(recipients, authState);
    if (!name) {
      router.push(`/login?return_url=/payment?${searchParams && searchParams.toString()}&discounts=${cartDiscounts}`);
      return;
    }
    if (name) {
      // mixpanel.track(`Voucher Purchase Initiated`);
      logEvent('cart', 'cart_loaded', cartTotalAmount ? Number(cartTotalAmount) : 0, 'cart_loaded');
      router.push(`/payment?discounts=${cartDiscounts}`);
    }
  };

  useEffect(() => {
    const cowryItemsInCart = cart?.cartItems.filter((item) => item.deal.merchantName === 'Cowry') || [];
    const totalCowryValue = cowryItemsInCart.reduce((sum, item) => {
      return sum + (item.redemptionInputValue || 0);
    }, 0);
    setTotalCowryValue(totalCowryValue);
  }, [cart]);

  const zaItemsInCart = cart?.cartItems.filter((item) => item.deal.countryCode === 'ZA');
  const zaItem = zaItemsInCart && zaItemsInCart.length > 0 ? true : false;

  const isUserVerified = verificationStatus === 'approved';

  const verify = async () => {
    setIsVerifying(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_USERS_V1_URL}/verify-email?userId=${authState.id}`, {
        method: 'GET',
      });
      if (response.ok) {
        toast.success('Check your email for the next steps.');
      }
    } catch (error) {
      toast.error('Error sending email, please try again');
    } finally {
      setIsVerifying(false);
    }
  };

  const getTotalItemCount = (cartItems) => {
    return cartItems?.reduce((total, item) => total + (item.quantity || 0), 0) || 0;
  };

  return (
    <div className={styles.cartContainer}>
      <div className={styles.cartMainSection}>
        <div className={styles.cartHeader}>
          <h1>Cart</h1>
          {cartItems?.length > 0 && (
            <span className={styles.itemCount}>
              {getTotalItemCount(cartItems)} {getTotalItemCount(cartItems) === 1 ? 'item' : 'items'}
            </span>
          )}
        </div>

        {cartItems?.length === 0 && (
          <div className={styles.cartItemsList}>
            <p style={{ backgroundColor: 'lightgreen', padding: '10px', borderRadius: '5px' }}>Cart is empty.</p>
          </div>
        )}
        {!isUserVerified && (
          <div className={styles.cartItemsList}>
            <p style={{ backgroundColor: 'lightgreen', padding: '10px', borderRadius: '5px' }}>
              Checkout can only be initiated when you are verified. Please check your email for a verification link sent
              to you during the sign-up process. Alternatively you can receive an email with the verification link by
              clicking the button below.
            </p>
            <button className={styles.verifyButton} onClick={verify} disabled={isVerifying}>
              {isVerifying ? 'Sending email...' : 'Verify KYC'}
            </button>
          </div>
        )}

        <div className={styles.cartItemsList}>
          {cartItems &&
            cartItems.map((item) => (
              <div key={item.cartItemId} className={styles.cartItem}>
                <div className={styles.leftSection}>
                  <div className={styles.itemImageContainer}>
                    <img src={item.deal.voucherImageUrl} alt={item.deal.voucherName} className={styles.itemImage} />
                  </div>
                  <div className={styles.itemDetails}>
                    <span className={styles.itemName}>
                      {item.quantity} x {item.deal.voucherName}
                    </span>
                    <span className={styles.itemPrice}>
                      {item.deal.redemptionCurrency} {item.redemptionInputValue.toFixed(2)}
                    </span>
                    {/* <div className={styles.quantityControl}>
                      <button className={styles.quantityButton}>
                        <img
                          src="/img/shop/minus-circle.svg"
                          className={styles.quantityButtonimg}
                        />
                      </button>
                      <input
                        type="text"
                        value="1"
                        className={styles.quantityInput}
                        readOnly
                      />
                      <button className={styles.quantityButton}>
                        <img
                          src="/img/shop/add-circle.svg"
                          className={styles.quantityButtonimg}
                        />
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className={styles.rightSection}>
                  <button
                    className={styles.deleteButton}
                    onClick={() => removeFromCartHandler(item.cartItemId)}
                    aria-label="remove"
                  >
                    <img className={styles.trashIcon} src="/img/shop/trash.svg" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {cartItems?.length === 0 || !isUserVerified ? <></> : <CartSummary buttonText={'Continue to Recipient'} />}
    </div>
  );
};

export default Cart;
