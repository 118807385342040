import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../styles/signup.module.css';

// import { logEvent } from '@/utils/logEvent';

const VerificationResult = () => {
  const location = useLocation();

  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  };

  const areQueryParamsValid = (inquiryId, referenceId, status) => {
    return inquiryId && referenceId && status;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inquiryId = params.get('inquiry-id');
    const referenceId = params.get('reference-id');
    const status = params.get('status');

    if (areQueryParamsValid(inquiryId, referenceId, status)) {
      postVerificationResults(inquiryId, referenceId, status);
    }
  }, [location]);

  const postVerificationResults = async (inquiryId, referenceId, status) => {
    const veriUrl = `${process.env.REACT_APP_API_USERS_URL}/verification-result`;
    const requestBody = {
      inquiryId,
      referenceId,
      status,
    };

    try {
      const response = await fetch(veriUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message || 'Error posting verification results');
      }
      //   if (status) {
      //     logEvent('user_acquisition', `verify_identity_${status}`, 0, `verify_identity_${status}`);
      //   }
    } catch (error) {
      if (error instanceof Error) {
        toast.warn(`Error: ${error?.message}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src="/img/auth/logo-black2.png" alt="Lipaworld logo" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.welcomeText}>
          <h1>Account verification</h1>
          <p>Thank you. We are processing your request. A confirmation email will be sent once completed.</p>
          <br />
          <br />
          <Link to="/" className={styles.actionButton} style={{ padding: '1.5rem' }}>
            Browse our products
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerificationResult;
