import React from 'react';
import styles from './SupportedCountries.module.css';

const SupportedCountries = () => {
  const countries = [
    { name: 'Nigeria', currency: 'NGN', flag: '/img/stablecoin/nigeria.png' },
    { name: 'Ghana', currency: 'GHS', flag: '/img/stablecoin/ghana.png' },
    { name: 'South Africa', currency: 'ZAR', flag: '/img/stablecoin/south-africa.png' },
    { name: 'Kenya', currency: 'KES', flag: '/img/stablecoin/kenya.png' },
    { name: 'Zimbabwe', currency: 'ZAR', flag: '/img/stablecoin/zimbabwe.png' },
    { name: 'Tanzania', currency: 'TZX', flag: '/img/stablecoin/tanzania.png' },
    { name: 'Uganda', currency: 'UGX', flag: '/img/stablecoin/uganda.png' },
    { name: 'Guinea Bissau', currency: 'XOF', flag: '/img/stablecoin/guinea-bissau.png' },
    { name: 'Benin', currency: 'XOF', flag: '/img/stablecoin/benin.png' },
    { name: 'Burkina Faso', currency: 'XOF', flag: '/img/stablecoin/burkina-faso.png' },
    { name: 'Ivory Coast', currency: 'XOF', flag: '/img/stablecoin/ivory-coast.png' },
    { name: 'Mali', currency: 'XOF', flag: '/img/stablecoin/mali.png' },
    { name: 'Niger', currency: 'XOF', flag: '/img/stablecoin/niger.png' },
    { name: 'Senegal', currency: 'XOF', flag: '/img/stablecoin/senegal.png' },
    { name: 'Cameroon', currency: 'XAF', flag: '/img/stablecoin/cameroon.png' },
    { name: 'Central African Republic', currency: 'XAF', flag: '/img/stablecoin/central-african-republic.png' },
    { name: 'Chad', currency: 'XAF', flag: '/img/stablecoin/chad.png' },
    { name: 'Republic of the Congo', currency: 'XAF', flag: '/img/stablecoin/republic-of-the-congo.png' },
    { name: 'Equatorial Guinea', currency: 'XAF', flag: '/img/stablecoin/equatorial-guinea.png' },
    { name: 'Gabon', currency: 'XAF', flag: '/img/stablecoin/gabon.png' },
  ];

  return (
    <div className={styles.supportedCountriesContainer} data-aos="fade-left">
      <h2 className={styles.mainTitle}>Send Money to Africa with Lipaworld</h2>
      <p className={styles.subtitle}>
        Supported Countries and Currencies in Your USDC Stablecoin Wallet:
      </p>

      <div className={styles.countriesGrid}>
        {countries.map((country, index) => (
          <div key={index} className={styles.countryCard}>
            <div className={styles.flagCircle}>
              <img 
                src={country.flag} 
                alt={`${country.name} flag`}
                className={styles.flagImage}
              />
            </div>
            <div className={styles.currencyCode}>{country.currency}</div>
            <div className={styles.countryName}>{country.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportedCountries;