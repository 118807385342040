/**
 * Checks if a recipient needs to provide Cowry phone number
 * @param {Object} cart - The cart object containing cart items
 * @param {Object} recipient - The recipient object
 * @returns {boolean} - True if recipient needs Cowry number
 */
export const needsCowryNumber = (cart, recipient) => {
  const hasCowryProducts = cart?.cartItems?.some((item) => item.deal.merchantName === 'Cowry');
  return hasCowryProducts && !recipient?.cowryPhoneNumber;
};

/**
 * Checks if a recipient needs to provide delivery address
 * @param {Object} cart - The cart object containing cart items
 * @param {Object} recipient - The recipient object
 * @returns {boolean} - True if recipient needs delivery address
 */
export const needsDeliveryAddress = (cart, recipient) => {
  const hasDeliveryFee = cart?.cartItems?.some((item) => item.deliveryFee !== null && item.deliveryFee > 0);
  return hasDeliveryFee && !recipient?.streetAddress;
};

/**
 * Validates if a recipient meets all requirements for checkout
 * @param {Object} cart - The cart object containing cart items
 * @param {Object} recipient - The recipient object
 * @returns {Object} - Validation result with status and message
 */
export const validateRecipientForCheckout = (cart, recipient) => {
  if (!recipient) {
    return {
      isValid: false,
      message: 'Please select a recipient',
    };
  }

  if (needsCowryNumber(cart, recipient)) {
    return {
      isValid: false,
      message: 'Recipient needs to provide Cowry phone number',
    };
  }

  if (needsDeliveryAddress(cart, recipient)) {
    return {
      isValid: false,
      message: 'Recipient needs to provide delivery address in Zimbabwe',
    };
  }

  return {
    isValid: true,
    message: '',
  };
};
